import './styles/global.css'
import Button from '@mui/material/Button';
import { useState, useCallback, ChangeEvent } from 'react';
import {  Card, TextareaAutosize } from '@mui/material';
import { debounce } from 'lodash';


function App() {
  const [translatedText, setTranslatedText] = useState<string>()
  
  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    fetch("/uwu-now", {
      method: 'POST', 
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({textToUwu: event.target.value})
    }).then(async (result) => setTranslatedText((await result.json()).uwu.replaceAll('"','')))
  }
  
  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 300)
  ,[])

  return (
    <div>
      <div className="m-4 sm:mt-20 sm:m-auto sm:w-[600px] md:w-[700px] lg:w-[940px]">
        <div className="flex flex-col text-center">
          <h1 className="text-4xl font-extrabold">
          ❤️w❤️
          </h1>
          <h2 className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Hewping you UwU woud & pwoud since 2023 OwO
          </h2>
        </div>
        <Card className="p-8 !bg-[#ebf0c1]">
          <div className="flex">
            <TextareaAutosize 
              className="w-full rounded p-3 shadow" 
              onChange={debouncedChangeHandler} 
              placeholder="Begin your UwU journey" />
          </div>
          <div className="m-8" />
          {translatedText ? 
          <div className="flex justify-between items-center shadow bg-white rounded p-3">
            <p>
              {/* TODO: Support new lines */}
              {translatedText}
            </p>
            {/* TODO: Customize material themes */}
            <Button className="shadow max-h-[40px] !min-w-[160px] !bg-[#bbf298]" onClick={() => {
              navigator.clipboard.writeText(translatedText)
            }}>
              <span className="font-bold">
              ${`Cwopy >w<`}
              </span>
            </Button>
          </div> : <div className="flex h-[60px] justify-center items-center shadow bg-white rounded">
            Our UwU team is standing by (U﹏U).
            </div> }
        </Card>
      </div>
    </div>
  );
}

export default App;
